import React from 'react';
import Typist from 'react-typist-component';
import Container from 'react-bootstrap/Container';
import { Jumbotron } from './migration';
import { Icon } from '@iconify/react';

const MainBody = React.forwardRef(({ title, message, jobtitle, icons }, ref) => {
  return (
    <Jumbotron
      fluid
      id="home"
      className="main title bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
    >
      {/* <div id="stars"></div> */}
      <Container className="text-center">
        <h1 ref={ref} className="display-1">
          {title}
        </h1>
        <div className="lead typist">
          <Typist loop cursor={<span className="cursor">|</span>}>
            {message}
            <Typist.Delay ms={1500} />
            <Typist.Backspace count={message.length} />
            {jobtitle}
            <Typist.Delay ms={1500} />
            <Typist.Backspace count={jobtitle.length} />
          </Typist>
        </div>

        <div className="py-5">
          {icons.map((icon, index) => (
            <a
              key={`social-icon-${index}`}
              target="_blank"
              rel="noopener noreferrer"
              href={icon.url}
              aria-label={`My ${icon.image.split('-')[1]}`}
            >
              <Icon icon={`fa6-brands:${icon.image}`} className="socialicons" fontSize={60} />
            </a>
          ))}
        </div>
        <a
          className="btn btn-outline-light btn-lg "
          href="#aboutme"
          role="button"
          aria-label="Learn more about me"
        >
          More about me
        </a>
        <p></p>
        <a
          className="btn btn-outline-light btn-lg"
          href="https://www.buymeacoffee.com/ankitrauniyar"
          target="_blank"
          rel="noopener noreferrer"
          role="button"
          aria-label="Buy me a coffee"
        >
          Buy me a coffee
        </a>
      </Container>
    </Jumbotron>
  );
});

export default MainBody;
