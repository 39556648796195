import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Jumbotron } from './migration';
import { Icon as Iconify } from '@iconify/react';
import { ThemeContext } from '../../App';

const TechSkills = () => {
  const theme = useContext(ThemeContext).theme;

  const icons = useMemo(() => {
    return [
      { name: 'AWS', icon: `skill-icons:aws-${theme}` },
      { name: 'Azure', icon: `skill-icons:azure-${theme}` },
      { name: 'PowerShell', icon: `skill-icons:powershell-${theme}` },
      { name: 'Linux', icon: `skill-icons:linux-${theme}` },
      { name: 'Bash', icon: `skill-icons:bash-${theme}` },
      { name: 'React.js', icon: `skill-icons:react-${theme}` },
      { name: 'JavaScript', icon: `skill-icons:javascript` },
      { name: 'Node.js', icon: `skill-icons:nodejs-${theme}` },
      { name: 'Docker', icon: `skill-icons:docker` },
      { name: 'MySQL', icon: `skill-icons:mysql-${theme}` },
      { name: 'Git', icon: `skill-icons:git` },
      { name: 'GitHub', icon: `skill-icons:github-${theme}` },
      { name: 'GitLab', icon: `skill-icons:gitlab-${theme}` },
      { name: 'GraphQL', icon: `skill-icons:graphql-${theme}` },
      { name: 'Postman', icon: `skill-icons:postman` },
      { name: 'WordPress', icon: `skill-icons:wordpress` },
      { name: 'Cloudflare', icon: `skill-icons:cloudflare-${theme}` },
      { name: 'Grafana', icon: `skill-icons:grafana-${theme}` }
    ];
  }, [theme]);
  const [visibleIcons, setVisibleIcons] = useState([]);
  const [isAllVisible, setIsAllVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    }
  }, []);

  const IconCard = ({ icon, name }) => (
    <div className={`icon-card p-3 text-center rounded`}>
      <Iconify icon={icon} height={60} />
      <p className="m-2 icon-name">{name}</p>
    </div>
  );

  useEffect(() => {
    setVisibleIcons(isMobile ? icons.slice(0, 6) : icons);
    setIsAllVisible(!isMobile);
  }, [icons, isMobile]);

  const handleClick = () => {
    setVisibleIcons(icons);
    setIsAllVisible(!isAllVisible);
    sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <section id="skills" ref={sectionRef}>
      <Jumbotron fluid className="techskills">
        <Container>
          <h2 className="display-4 mb-5 text-center">Tech Skills</h2>
          <Row>
            {visibleIcons.slice(0, isAllVisible ? icons.length : 6).map(({ name, icon }, index) => (
              <Col xs={6} sm={4} md={2} className="mb-4" key={index}>
                <IconCard icon={icon} name={name} />
              </Col>
            ))}
          </Row>
          {isMobile && (
            <Row>
              <Col className="text-center">
                <button
                  className={`btn btn-lg ${
                    theme === 'light' ? 'btn-outline-dark' : 'btn-outline-light'
                  }`}
                  onClick={handleClick}
                >
                  {isAllVisible ? 'Show Less' : 'Show More'}
                </button>
              </Col>
            </Row>
          )}
        </Container>
      </Jumbotron>
    </section>
  );
};

export default TechSkills;
