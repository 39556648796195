import React from 'react';
import CertCard from './CertCard';
import { Container, Row } from 'react-bootstrap';
import { Jumbotron } from './migration';

const Certifications = ({ certifications }) => {
  return (
    <section className="section" id="certifications">
      <Jumbotron fluid className="certifications">
        <Container>
          <h2 className="display-4 mb-5 text-center">{certifications.heading}</h2>
          <Row>
            {certifications.data.map((data, index) => {
              return <CertCard key={index} data={data} />;
            })}
          </Row>
        </Container>
      </Jumbotron>
    </section>
  );
};

export default Certifications;
