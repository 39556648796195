import React, { createContext } from 'react';
import { getInTouch } from './editable-stuff/config.js';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import GetInTouch from './components/home/GetInTouch.jsx';
import Home from './components/Home.jsx';
import Toggle from './components/home/Toggle.jsx';
import { useLocalStorage } from './hooks/useLocalStorage';

export const ThemeContext = createContext(null);
const App = () => {
  const titleRef = React.useRef();
  const [theme, setTheme] = useLocalStorage('theme', 'light');
  const toggleTheme = () => {
    setTheme((curr) => (curr === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div id={theme}>
        <Navbar ref={titleRef} />
        <Home ref={titleRef} />
        <Footer>
          <GetInTouch
            heading={getInTouch.heading}
            message={getInTouch.message}
            email={getInTouch.email}
          />
        </Footer>
        <Toggle />
      </div>
    </ThemeContext.Provider>
  );
};

export default App;
