import React from 'react';
import { mainBody, about, skills, certifications } from '../editable-stuff/config';
import MainBody from './home/MainBody.jsx';
import AboutMe from './home/AboutMe';
import Skills from './home/Skills';
import TechSkills from './home/TechSkills.jsx';
import Certifications from './home/Certifications';
import Blogs from './home/Blogs.jsx';

const Home = React.forwardRef((props, ref) => {
  return (
    <>
      <MainBody
        gradient={mainBody.gradientColors}
        title={`${mainBody.firstName} ${mainBody.middleName} ${mainBody.lastName}`}
        message={mainBody.message}
        jobtitle={mainBody.jobtitle}
        icons={mainBody.icons}
        ref={ref}
      />
      <AboutMe
        heading={about.heading}
        message={about.message}
        link={about.imageLink}
        imgSize={about.imageSize}
        resume={about.resume}
      />
      <TechSkills />
      <Certifications certifications={certifications} />
      {/* <Project
          heading={repos.heading}
          username={repos.gitHubUsername}
          length={repos.reposLength}
          specfic={repos.specificRepos}
        /> */}
      <Blogs />
      <Skills
        heading={skills.heading}
        hardSkills={skills.hardSkills}
        softSkills={skills.softSkills}
      />
    </>
  );
});

export default Home;
