import React, { useContext, useState } from 'react';
import emailjs from '@emailjs/browser';
import { ThemeContext } from '../../App';

const ContactForm = () => {
  const { theme } = useContext(ThemeContext);
  const [contactDetail, setContactDetail] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState('Send');
  const onChange = (e) => {
    const { name, value } = e.target;
    setContactDetail((prevContactDetail) => ({ ...prevContactDetail, [name]: value }));
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      setFormStatus('Sending...');
      const result = await emailjs.sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAIL_PUBLIC_API_KEY
      );
      if (result) {
        setFormStatus('Sent');
        setContactDetail({
          name: '',
          email: '',
          message: ''
        });
      }
    } catch (error) {
      console.log(error.text);
    }
  };

  return (
    <div className="m-4 lead text-center pb-3">
      <form onSubmit={sendEmail}>
        <div className="mb-3">
          <label className="form-label" htmlFor="name">
            Name
          </label>
          <input
            className="form-control"
            type="text"
            id="name"
            name="name"
            onChange={onChange}
            value={contactDetail.name}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input
            className="form-control"
            type="email"
            id="email"
            name="email"
            onChange={onChange}
            value={contactDetail.email}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="message">
            Message
          </label>
          <textarea
            className="form-control"
            id="message"
            onChange={onChange}
            value={contactDetail.message}
            name="message"
            required
          />
        </div>
        <button
          className={`btn ${theme === 'light' ? 'btn-outline-dark' : 'btn-outline-light'} btn-lg`}
          type="submit"
        >
          {formStatus}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
