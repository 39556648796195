import React, { useState, useEffect, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import { Jumbotron } from './migration';
import Row from 'react-bootstrap/Row';
import { Col, Badge, Table } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import { ThemeContext } from '../../App';
import axios from 'axios';

const Blogs = () => {
  const { theme } = useContext(ThemeContext);
  const [articles, setArticles] = useState([]);

  const getPostData = () => {
    axios
      .get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@rauniyarankit')
      .then((res) => {
        setArticles(res.data.items);
      })
      .catch((error) => {
        console.error('Error fetching blog posts:', error);
      });
  };

  useEffect(() => {
    getPostData();
  }, []);

  return (
    <Jumbotron fluid id="blogs" className="blogs m-0">
      <Container>
        <h2 className="display-4 pb-5 text-center">Blogs</h2>
        <Row>
          {articles.map((article, index) => (
            <Col md={6} key={article.id || index}>
              <a
                style={{ textDecoration: 'none', color: 'inherit' }}
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card className="card blogcard shadow-lg p-3 mb-5 rounded">
                  <Card.Body>
                    <Card.Title style={{ color: 'inherit' }}>{article.title}</Card.Title>
                    <Table className={`table-${theme}`} style={{ color: 'inherit' }}>
                      <tbody style={{ color: 'inherit' }}>
                        <tr>
                          <td style={{ color: 'inherit' }}>Categories:</td>
                          <td>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {article.categories.map((category) => (
                                <Badge
                                  className={`badge ${theme === 'light' ? 'bg-dark' : 'bg-light'}`}
                                  key={category}
                                  style={{
                                    margin: '2px',
                                    color: theme === 'light' ? '#fff' : '#000'
                                  }}
                                >
                                  {category}
                                </Badge>
                              ))}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ color: 'inherit' }}>Published:</td>
                          <td style={{ color: 'inherit' }}>
                            {moment(article.pubDate).format('D MMM, YYYY')}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </Jumbotron>
  );
};

export default Blogs;
