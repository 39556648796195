import React, { useContext, useState } from 'react';
import { useScrollPosition } from '../hooks/useScrollPosition';
import useResizeObserver from '../hooks/useResizeObserver';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from './home/migration';
import { mainBody, about } from '../editable-stuff/config.js';
import { ThemeContext } from '../App';

const Navigation = React.forwardRef((props, ref) => {
  const { theme } = useContext(ThemeContext);
  const [isTop, setIsTop] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navbarMenuRef = React.useRef();
  const [expanded, setExpanded] = useState(false);
  const navbarDimensions = useResizeObserver(navbarMenuRef);
  const navBottom = navbarDimensions ? navbarDimensions.bottom : 0;
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!navbarDimensions) return;
      currPos.y + ref.current.offsetTop - navbarDimensions.bottom > 5
        ? setIsTop(true)
        : setIsTop(false);
      setScrollPosition(currPos.y);
    },
    [navBottom]
  );

  React.useEffect(() => {
    if (!navbarDimensions) return;
    navBottom - scrollPosition >= ref.current.offsetTop ? setIsTop(false) : setIsTop(true);
  }, [navBottom, navbarDimensions, ref, scrollPosition]);

  return (
    <Navbar
      expanded={expanded}
      ref={navbarMenuRef}
      className={`px-3 fixed-top  ${!isTop ? 'navbar-white' : 'navbar-transparent'}`}
      expand="lg"
      variant={theme}
    >
      <Navbar.Brand className="navbar-brand" href={process.env.PUBLIC_URL + '/#home'}>
        {`<${mainBody.firstName} />`}
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className="toggler"
        onClick={() => setExpanded(!expanded)}
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav
          className="navbar-nav mr-auto"
          onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
        >
          <NavLink className="nav-item lead" href={process.env.PUBLIC_URL + '/#aboutme'}>
            About
          </NavLink>
          <NavLink className="nav-item lead" href={process.env.PUBLIC_URL + '/#skills'}>
            Skills
          </NavLink>
          <NavLink className="nav-item lead" href={process.env.PUBLIC_URL + '/#certifications'}>
            Certifications
          </NavLink>
          <NavLink className="nav-item lead" href={process.env.PUBLIC_URL + '/#blogs'}>
            Blogs
          </NavLink>
          <NavLink className="nav-item lead" href={process.env.PUBLIC_URL + '/#proficiency'}>
            Proficiency
          </NavLink>
          <NavLink className="nav-item lead" href={process.env.PUBLIC_URL + '/#getintouch'}>
            Get-In-Touch
          </NavLink>
          <NavLink
            className="nav-item lead"
            href={about.resume}
            target="_blank"
            rel="noreferrer noopener"
          >
            Resume
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
});

export default Navigation;
