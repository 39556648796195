import React, { useContext } from "react";

import { Col } from "react-bootstrap";
import { ThemeContext } from "../../App";
const CertCard = ({ data }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Col lg="6">
      <div className="pb-5 text-center">
        <img
          className="mb-3"
          src={theme === "light" ? data.badgeLight : data.badgeDark}
          alt=""
          height={258}
          width={258}
        />
        <p className="lead" style={{ fontWeight: "400" }}>
          {data.certname}
        </p>
        <p className="lead" style={{ marginTop: "-1em" }}>
          {data.date}
        </p>
        <a
          href={data.link}
          className={`btn ${
            theme === "light" ? "btn-outline-dark" : "btn-outline-light"
          } btn-lg`}
          role="button"
          aria-label="Verify"
          target="_blank"
          rel="noopener noreferrer"
        >
          Verify
        </a>
      </div>
    </Col>
  );
};

export default CertCard;
