import React, { useContext } from 'react';
import { ThemeContext } from '../../App';
import { Jumbotron } from './migration';

const AboutMe = ({ heading, message, link, imgSize, resume }) => {
  const { theme } = useContext(ThemeContext);
  const buttonClass = `btn btn-lg ${theme === 'light' ? 'btn-outline-dark' : 'btn-outline-light'}`;

  return (
    <Jumbotron id="aboutme" className="aboutme jumbotron jumbotron-fluid m-0">
      <div className="container container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-5 d-lg-block align-self-center">
            <img
              className="img-fluid border border-secondary rounded-circle"
              src={link}
              alt="profilepicture"
              width={imgSize}
              height={imgSize}
            />
          </div>
          <div className={`col-lg-7`}>
            <h2 className="display-4 mb-5 text-center">{heading}</h2>
            <p className="lead text-center">{message}</p>
            {resume && (
              <p className="lead text-center">
                <a
                  className={buttonClass}
                  href={resume}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                  aria-label="Resume/CV"
                >
                  Resume
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </Jumbotron>
  );
};

export default AboutMe;
