import React from 'react';
import Container from 'react-bootstrap/Container';
import { Icon } from '@iconify/react';

const Footer = ({ children }) => {
  return (
    <footer className="footer mt-auto py-5 text-center">
      <Container>
        {children}
        Made with <Icon icon="fa:heart" /> by{' '}
        <a
          rel="noopener"
          href="https://www.linkedin.com/in/rauniyarankit/"
          aria-label="My LinkedIn"
        >
          <span className="badge badge-footer">Ankit Rauniyar</span>
        </a>{' '}
        <p className="mt-2">
          <small className="text-muted">&copy; {new Date().getFullYear()} Ankit Rauniyar</small>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
