import React, { useContext } from 'react';
import { ThemeContext } from '../../App';
import { Icon } from '@iconify/react';

const Toggle = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  return (
    <>
      <button className="darkmodeswitch" onClick={toggleTheme} title="Dark Mode Switch">
        <Icon
          icon={`${theme === 'light' ? 'bi:moon-fill' : 'bi:sun-fill'}`}
          height={35}
          width={35}
          rotate="270deg"
        />
      </button>
    </>
  );
};

export default Toggle;
