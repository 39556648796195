import React from 'react';
import ContactForm from './ContactForm';
import { Col, Row } from 'react-bootstrap';

const GetInTouch = ({ heading, message, email }) => {
  return (
    <>
      <Row id="getintouch">
        <Col>
          <h2 className="display-4 pb-3 text-center">{heading}</h2>

          <p className="lead text-center ">
            {message}, <a href={`mailto:${email}`}>{email}</a>.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg="6">
          <ContactForm />
        </Col>
      </Row>
    </>
  );
};

export default GetInTouch;
